import Amplify  from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

Amplify.configure({
  Auth: {
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true  
  }
});