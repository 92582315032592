import React from "react";
import { fuseConf } from './config/fuse-matcher.js';

export default function Popup(props){
  let fuse = fuseConf(props.dropdownValues); 

  let result = []; 
  if(props.dropdownValues){
    props.dropdownValues.forEach(element => {
      let className = ""; 
      if(element === props.posibleValue){
        className = "dpd_sctd";
      }
      result.push(<li key={element} className={className} onMouseDown={() => {
        document.getElementById(props.dpd_input_id).value = element; 
        let result = fuse.search(element); 
        result = result.map(element => element.item);
        props.completed(element);
        props.setDropdownValueState(result); 
        props.deactivateDropdown();
      }}>{element}</li>)
    });
  }
  return(
    <ul>
      {result.length > 0 ? result : <li>Sin resultados</li>}
    </ul>
  );
}