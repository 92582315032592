import React, { useState, useEffect } from "react";
import Dropdown from './Dropdown';
import DisabledInput from "./DisabledInput";
import { varConf } from './config/input-variables.js'; 
import { fuseConf } from './config/fuse-matcher.js';

export default function CustomDropdown(props){  
  let { placeholder, dpd_id, image, dpd_input_id} = varConf(props.type); 
  let fuse = fuseConf(props.dropdownValues); 

  const [popupActivated, setPopupState] = useState(false);
  const [dropdownValues, setDropdownValueState] = useState(null);
  const [posibleValue, setPosibleValueState] = useState(null);

  // Helper functions
  const activateDropdown = () => {
    if(!popupActivated){
      setPopupState(true); 
      document.getElementById(dpd_input_id).focus();
    }
  }

  const deactivateDropdown = () => {
    setPopupState(false);
    if(props.valueSelected){
      document.getElementById(dpd_input_id).value = props.valueSelected;
    }
  }

  const selectValue = () => {
    setPopupState(false);
    props.completed(posibleValue);
    document.getElementById(dpd_input_id).value = posibleValue;
  }

  const filterDropdownValues = (event) => {
    try{
      let result = fuse.search(event.target.value); 
      result = result.map(element => element.item);
      setPosibleValueState(result[0]);
      setDropdownValueState(result);
    }catch(err){
      //Values not available yet
    }
  }

  useEffect(() => {
    setDropdownValueState(props.dropdownValues);
  }, [props.dropdownValues]); 

  if(props.disabled){
    return <DisabledInput containerId={dpd_id} inputId={dpd_input_id} image={image} placeholder={placeholder}></DisabledInput>
  }else{
    return(
      <button id={dpd_id} className="dpd"
        onFocus={() => activateDropdown()} 
        onBlur={() => deactivateDropdown()}
      >
        {popupActivated && <Dropdown completed={props.completed} deactivateDropdown={deactivateDropdown} dropdownValues={dropdownValues} setDropdownValueState={setDropdownValueState} posibleValue={posibleValue} dpd_input_id={dpd_input_id}></Dropdown>}
        <input id={dpd_input_id} type="text" autoComplete="off" placeholder={placeholder} 
          onKeyPress={(event) => {
            if(event.key === 'Enter' || event.keyCode === 13) selectValue(); 
          }} 
          onChange={(event) => {
            if(event.target.value === ""){
              if(props.emptyValue) {
                props.emptyValue(); 
              }
              setDropdownValueState(props.dropdownValues)
            }else{
              if(!popupActivated){
                activateDropdown();
              }
              filterDropdownValues(event); 
            }
          }} 
          onFocus={(event) => {
            event.target.value = ""; 
            setDropdownValueState(props.dropdownValues)
          }}
        ></input>
        <figure>
          <img src={image} alt="Dropdown icon"></img>
        </figure>
      </button>
    );
  }
}