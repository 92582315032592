import React from "react";
export default function DeletePopup(props) {
  return (<div id="fspup" onClick={(event) => {
    if(event.target !== document.getElementById('fspup')){
      return; 
    }

    props.close(); 
  }}>
    <div id="cnpup">
      <p id="tle">{props.title}</p>
      <p id="cnpup_des">{props.message}</p>
      <div id="act_rw"> 
        <button id="pry_btn" onClick={() => {
          //Get emails
          //async axios delete emails
          props.close();
        }}>Eliminar</button>
        <p id="sdry_btn" onClick={() => props.close()}>Cancelar</p>
      </div>
    </div>
  </div>);
}
