import React from "react";

export const showCheckbox = (event) => {
  const checkbox = event.target.parentNode.getElementsByClassName('wrh_cb');
  try{
    checkbox[0].style.display = "flex";
  }catch(err){}
}

export const hideCheckbox = (event) => {
  let checkboxes = document.getElementsByClassName('cb'); 
  const checked = []; 
  for(let i = 0; i < checkboxes.length; i++){
    if(checkboxes[i].checked){
      checked.push(checkboxes[i]);
    }
  }

  checkboxes = document.getElementsByClassName('wrh_cb'); 
  if(checked.length === 0){
    const checkbox = event.target.parentNode.getElementsByClassName('wrh_cb');
    for(let i = 0; i < checkboxes.length; i++){
      checkboxes[i].style.display = "none";
    }
    try{
      checkbox[0].style.display = "none";
    }catch(err){}
  }
}

export const checkboxSelected = (props) => {
  if(!props.activated){
    props.callback();
  }

  let checkboxes = document.getElementsByClassName('cb'); 
  const checked = []; 
  for(let i = 0; i < checkboxes.length; i++){
    if(checkboxes[i].checked){
      checked.push(checkboxes[i]);
    }
  }
  props.setItemsSelected(checked.length);
  
  const checkboxDiv = document.getElementsByClassName('wrh_cb'); 
  if(checked.length > 0){
    for(let i = 0; i < checkboxDiv.length; i++){
      checkboxDiv[i].style.display = "flex";
    }
  }else{
    for(let i = 0; i < checkboxDiv.length; i++){
      checkboxDiv[i].style.display = "none";
    }
  }

  if(props.activated && checked.length === 0){
    props.callback();
  }
}

export function Checktool(props){
  return (props.selected ? (<div id="chtol">
    <div id="chtol_lft">
    <p id="chtol_ne">{props.itemsQuantity}</p>
      <p id="chtol_d">Elemento(s) seleccionado(s)</p>
    </div>
    <div id="chtol_act">
      <div className="chtol_act" onClick={() => props.delete()}>
        <figure>
          <img src="../delete.svg" alt="Edit"></img>
        </figure>
        <p>Eliminar</p>
      </div>
    </div>
  </div>): null)
}

export function Checkbox(props){
  return (<div className="wrh_cb">
    <input className="cb" type="checkbox" onChange={() => {
      checkboxSelected(props); 
    }}/>
  </div>)
}
