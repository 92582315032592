import React from "react";

export default function Menu(props){
  const menu = props.items.map(item => {
    return (<li key={item.label} onClick={() => {
      item.action(); 
      props.close();
    }}>{item.label}</li>); 
  }); 

  return (<div id="mnu">
    <ul>
      {menu}
    </ul>
  </div>); 
}
