import React from "react";
import CustomInput from './CustomInput'

export default function ChangeEmail(props) {
  return (<div id="fspup" onClick={(event) => {
    if(event.target !== document.getElementById('fspup')){
      return; 
    }

    props.close(); 
  }}>
    <div id="cnpup">
      <p id="tle">{props.title}</p>
      <p id="cnpup_des">Te haremos llegar un código de seguridad a tu nuevo correo para validarlo.</p>
      <CustomInput placeholder="Nuevo correo electrónico" type="text"></CustomInput>
      <button className="cnpup_btn">Enviar código</button>
    </div>
  </div>);
}
