import { Auth } from 'aws-amplify';
import React, { useState } from "react";
import CustomInput from './CustomInput';
import { Link, useHistory } from "react-router-dom";
// import { getAccountData } from './helper';
import { Loading, Completed } from './GenericPopup';


export function SignIn(props){
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);

  const history = useHistory();

  return (
    <div id="lgn_cntr">
      {loading && <Loading/>}
      {completed && <Completed message={completedMessage} close={() => setProcessStatus(false)}/>}
      <p id="lgn_ttl">Inicia sesión</p>
      <form onSubmit={async (event) => {
        event.preventDefault(); 
        setLoading(true);
        try {
          const session = await Auth.signIn(document.getElementById('ipt_login_username').value, document.getElementById('ipt_login_password').value);
          sessionStorage.setItem('auth', true);
          sessionStorage.setItem('session', JSON.stringify(session));
          // getAccountData(session);

          history.push({
            pathname:  "/autoparts/search"
          })
        } catch (error) {
          setLoading(false);
          setProcessStatus(true)
          if(error.code === "NotAuthorizedException"){
            setProcessStatusMessage('Correo o contraseña incorrectos')
          }else if(error.code === "UserNotConfirmedException"){
            setProcessStatusMessage('El correo no ha sido validado')
          }else{
            setProcessStatusMessage('Error al iniciar sesión')
          }
        }
      }}>
        <CustomInput id="ipt_login_username" placeholder="Email" type="email" required={true}></CustomInput>
        <CustomInput id="ipt_login_password" placeholder="Contraseña" type="password" required={true}></CustomInput>
        <button id="lgn_btn">Iniciar sesión</button>
      </form>
      <p id="lgn_rcvr">¿Olvidaste tu contraseña? <Link to="/password-recovery"><span>Recuperar</span></Link></p>
    </div>
  )
}