import Fuse from 'fuse.js';

export const fuseConf = (dropdownValues) => {
  // String matcher 
  let fuse; 
  const options = {
    includeScore: true, 
    threshold: 0.1, 
    distance: 40
  }
  if(dropdownValues){
    fuse = new Fuse(dropdownValues, options)
  }

  return fuse; 
}