import { Auth } from 'aws-amplify';
import React, { useState } from "react";
import CustomInput from './CustomInput';
import { Loading, Completed } from './GenericPopup';

import {
  Link, 
  useHistory
} from "react-router-dom";

export function PRSendEmail(props){
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const history = useHistory();

  return(
    <div id="lgn_cntr">
      {loading && <Loading/>}
      {completed && <Completed type="ERROR" message={completedMessage} close={() => setProcessStatus(false)}/>}
      <p id="lgn_ttl">Recuperación de contraseña</p>
      <p id="cnpup_des">Por motivos de seguridad, te enviaremos un código de verificación a tu correo.</p>
      <form onSubmit={async(event) => {
        event.preventDefault();
        try{
          setLoading(true);
          const email = document.getElementById('ipt_pr_email').value; 
          await Auth.forgotPassword(email); 
          props.saveEmail(email);
  
          history.push({
            pathname:  "/password-recovery/update"
          })
        }catch(err){
          if(err.code === "LimitExceededException"){
            setProcessStatusMessage('El límite de intentos ha sido excedido, favor de intentar más tarde');
          }
          setLoading(false);
          setProcessStatus(true)
        }
      }}>
        <CustomInput id="ipt_pr_email" placeholder="Email" type="email"></CustomInput>
        <div id="lgn_lft">
          <input id="lgn_btn" type="submit" value="Enviar código"></input>
          <Link to="/login"><p>Cancelar</p></Link>
        </div>
      </form>
    </div>
  );
} 

export function PRUpdate(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const history = useHistory();

  return (
    <div id="lgn_cntr">
      {loading && <Loading/>}
      {completed && <Completed type="ERROR" message={completedMessage} close={() => setProcessStatus(false)}/>}
      <p id="lgn_ttl">Recuperación de contraseña</p>
      <p id="cnpup_des">Te hemos enviado un código de verificación, es necesario ingresarlo para poder continuar.</p>
      <form onSubmit={async(event) => {
        event.preventDefault();
        setLoading(true);
        const code =  document.getElementById('ipt_password_recovery_code').value;
        const newPassword = document.getElementById('ipt_password_recovery_new_password').value;
        
        try{
          await Auth.forgotPasswordSubmit(props.email, code, newPassword); 
          await Auth.signIn(props.email, newPassword);
          sessionStorage.setItem('auth', true);
          history.push({
            pathname:  "/articles"
          })
        }catch(err){
          setLoading(false);
          setProcessStatus(true); 
          if(err.code === "CodeMismatchException"){
            setProcessStatusMessage('El código ingresado es incorrecto');
          }
        }
      }}>
        <CustomInput id="ipt_password_recovery_code" placeholder="Código de verificación (6 dígitos)" type="text" required={true} pattern="\d{6}"></CustomInput>
        <CustomInput id="ipt_password_recovery_new_password" placeholder="Nueva contraseña (mínimo 8 caracteres)" type="password" required={true} pattern="^.{8,}$"></CustomInput>
        <div id="lgn_lft">
          <input id="lgn_btn" type="submit" value="Cambiar contraseña"></input>
          <Link to="/password-recovery"><p>Cancelar</p></Link>
        </div>
      </form>
    </div>
  );
}
