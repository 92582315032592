import React, { useState, useEffect } from "react";
import { hideCheckbox, showCheckbox, Checktool, Checkbox } from './Checktool'; 
import DeletePopup from './DeletePopup'; 
import EmployeeEdition from "./EmployeeCreation";
import axios from 'axios';
import { Base64 } from 'js-base64';

export default function Employees(){
  const [checktoolActivated, setChecktoolState] = useState(false);
  const [checktoolItemsQuantity, setChecktoolItemsQuantityState] = useState(0);
  const [deleteEmployeeActivated, setEmployeeDeletionPopupState] = useState(false);
  const [newEmployeeActivated, setNewEmployeePopupState] = useState(false);
  const [gotEmployees, setGotEmployeesState] = useState(false); 
  const [employeesData, setEmployeesState] = useState(sessionStorage.getItem('employees')); 
  const employees = [];

  useEffect(() => {
    if(!gotEmployees){
      (async () => {
        try{
          let response = await axios({
            method: 'get',
            url: process.env.REACT_APP_GET_EMPLOYEES
          });
          
          console.log(response)
          setEmployeesState(JSON.parse(response.data.body).employees);
          sessionStorage.setItem('employees', JSON.stringify(JSON.parse(response.data.body).employees)); 
          setGotEmployeesState(true); 
        }catch(err){
          console.log(err);
        }
      })(); 
    }

  }, [gotEmployees]);

  const employeesAmount = employeesData ? employeesData.length : 0; 
  for(let i = 0; i < employeesAmount ; i++){
    const image = 'https://d36f5t8nk78xg4.cloudfront.net/' + Base64.encode(JSON.stringify({
      "bucket": "arboledas-storage",
      "key": employeesData[i].image_location,
      "edits": {
        "resize": {
          "width": 50,
          "height": 50,
          "fit": "cover",
        },
      }
    }));
    
    employees.push(<div key={i} className={i === 0 ? "wrh_tb_r wrh_tb_frst_chld" : "wrh_tb_r"}  /*onMouseLeave={hideCheckbox} onMouseEnter={showCheckbox}*/>
      <Checkbox setItemsSelected={(value) => setChecktoolItemsQuantityState(value)} callback={() => {
        setChecktoolState(!checktoolActivated); 
      }} activated={checktoolActivated}></Checkbox>
      <figure id="emp_pfl_img">
        <object data={image}>
          <img src="/profile.svg" alt="No profile"></img> 
        </object>
      </figure>
      <p className="wrh_tb_r_nm">{employeesData[i].fullname}</p>
      <p className="wrh_tb_r_un">{employeesData[i].job_title}</p>
    </div>); 
  }

  return(
    <div id="wh_s">
      {newEmployeeActivated && <EmployeeEdition title="Nuevo colaborador" close={() => {setNewEmployeePopupState(!newEmployeeActivated)}}></EmployeeEdition>}
      {deleteEmployeeActivated && <DeletePopup title="Eliminar colaborador" close={() => {setEmployeeDeletionPopupState(!deleteEmployeeActivated)}} message="Está a punto de eliminar al colaborador seleccionado ¿está seguro de querer continuar?. Esta acción no se puede revertir."></DeletePopup>}
      <Checktool selected={checktoolActivated} itemsQuantity={checktoolItemsQuantity} delete={() => setEmployeeDeletionPopupState(!deleteEmployeeActivated)}></Checktool>
      <div id="emp_cntr">
        <div id="emp_hdr">
          <button id="nw_emp" className="dtld_btn" onClick={() => setNewEmployeePopupState(!newEmployeeActivated)}>Registrar</button>
          <p id="emp_amt">{employeesData ? employeesData.length : 0} colaboradores</p>
          <div id="emp_tb_hdr">
            <p id="emp_tb_img_col">Foto</p>
            <p id="emp_tb_nm_col">Nombre</p>
            <p id="emp_tb_jt_col">Puesto</p>
          </div>
        </div>
        <div id="wrh_tb">
          {employees}
        </div>
      </div>
    </div>
  )
}