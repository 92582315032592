import React, { useState } from "react";
import CustomInput from './CustomInput'
import Dropzone from './DropZone'; 
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Loading, Completed } from './GenericPopup';

export default function EmployeeEdition(props) {
  const [stepSelected, setSectionSelected] = useState(1);
  const [status, setStatus] = useState(null);
  const [articleData, setArticleDataState] = useState({
    fullname: null,
    job_title: null, 
    phone: null, 
    email: null,
    files: null
  });

  let render; 
  switch(stepSelected){
    case 1: 
      render =  <FirstSection 
        saveData={setArticleDataState} 
        data={articleData} {...props} 
        changeSection={() => setSectionSelected(2)}
        completeProcess={(status) => {
          setSectionSelected(4); 
          setStatus(status)
        }}
        ></FirstSection>
      break; 
    case 2: 
      render = <Loading></Loading>
      break; 
    case 4: 
      render = <Completed type={status} message={status === "SUCCESS" ? "Colaborador registrado exitosamente" : "Lo sentimos, hubo un error al registrar al colaborador"} close={props.close}></Completed>
      break
    default: 
      break; 
  }
  

  return (<div id="fspup" onClick={(event) => {
    if(event.target !== document.getElementById('fspup')){
      return; 
    }
    props.close(); 
    }}>
    {render}
  </div>);
}

function FirstSection(props){
  return(<div id="cnpup">
    <p id="tle">{props.title}</p>
    <Dropzone saveFiles={props.saveData} data={props.data}></Dropzone>
    <form onSubmit={async (event) => {
        event.preventDefault();
        const data = {
          fullname: document.getElementById('ipt_new_employee_name').value,
          job_title: document.getElementById('ipt_new_employee_job_title').value, 
          phone: document.getElementById('ipt_new_employee_phone').value,
          email: document.getElementById('ipt_new_employee_email').value,
        }

        props.saveData({...props.data, ...data});
        props.changeSection();
        const files = props.data.files; 

        function FileReaderPromisified(inputFile){
          const temporaryFileReader = new FileReader();
        
          return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
              temporaryFileReader.abort();
              reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
              const ext = inputFile.type.match(/\/.*/)[0].replace('/', '.');
              resolve({base64: temporaryFileReader.result, contentType: inputFile.type, name: uuidv4() + inputFile.lastModified + inputFile.size + ext});
            };

            temporaryFileReader.readAsDataURL(inputFile);
          });
        };

        let filesPromises = [];
        
        try{
          for(let i = 0; i < files.length; i++){
            filesPromises.push(FileReaderPromisified(files[i]));  
          }
        }catch(err){
          // Empty array of files
        }

        let base64Files = await Promise.all(filesPromises);
        data.files = base64Files; 
        try{
          await axios.post(process.env.REACT_APP_CREATE_USER, data);
          props.completeProcess('SUCCESS');
        }catch(err){
          props.completeProcess('ERROR');
        }
    }}>
      <CustomInput id="ipt_new_employee_name" placeholder="Nombre" type="text" required={true}></CustomInput>
      <div className="cnpup_rw">
        <CustomInput id="ipt_new_employee_job_title" placeholder="Puesto" type="text"></CustomInput>
        <div className="cnpup_rw_s"></div>
        <CustomInput id="ipt_new_employee_phone" placeholder="Teléfono" type="text"></CustomInput>
      </div>
      <CustomInput id="ipt_new_employee_email" placeholder="Email" type="email" required={true}></CustomInput>
      <input className="cnpup_btn" type="submit" value="Continuar"></input>
    </form>   
  </div>); 
}
