import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  useHistory
} from "react-router-dom";
import TrafficByLocation from './metrics/TrafficByLocation';
import Traffic from './metrics/Traffic';
import Employees from './Employees';
import Profile from './Profile';

export default function Dashboard() {
  const history = useHistory();
  const [path, setPath] = useState(window.location.pathname);  
  
  return (
    <Router>
      <div id="wr">
        <div id="nv">
          <div>
            <figure id="lg">
              <img src="/logo_white.svg" alt="Logo"></img>
            </figure>
            <ul>
              <li className={path === "/autoparts/search" ? "tb_slct" : ""}>
                <Link to="/autoparts/search" onClick={() => setPath("/autoparts/search")}>Buscador de autopartes</Link>
              </li>
              <li className={path === "/autoparts/metric/traffic-location" ? "tb_slct" : ""}>
                <Link to="/autoparts/metric/traffic-location" onClick={() => setPath("/autoparts/metric/traffic-location")}>&nbsp;&nbsp;&nbsp;&nbsp;Tráfico por zona</Link>
              </li>
              <li className={path === "/autoparts/metric/traffic" ? "tb_slct" : ""}>
                <Link to="/autoparts/metric/traffic" onClick={() => setPath("/autoparts/metric/traffic")}>&nbsp;&nbsp;&nbsp;&nbsp;Tráfico</Link>
              </li>
              <li className={path === "/employees" ? "tb_slct" : ""}>
                <Link to="/employees" onClick={() => setPath("/employees")}>Colaboradores</Link>
              </li>
              <li className={path === "/profile" ? "tb_slct" : ""}>
                <Link to="/profile" onClick={() => setPath("/profile")}>Perfil</Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="lnpt" onClick={async () => {
                try {
                  await Auth.signOut({ global: true });
                  sessionStorage.setItem('auth', false);
                  sessionStorage.setItem('company', null);
                  sessionStorage.setItem('user', null);
                  history.push({
                    pathname:  "/login"
                  })
                } catch (error) {
                  console.log('error signing out: ', error);
                }
              }}>Cerrar sesión</li>
            </ul>
          </div>
        </div>
        <Switch>
           <Route path="/autoparts/search">
            <AutopartsSearch />
          </Route>
          <Route path="/autoparts/metric/traffic-location">
            <TrafficByLocation />
          </Route>
          <Route path="/autoparts/metric/traffic">
            <Traffic />
          </Route>
          <Route path="/employees">
            <Employees />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function AutopartsSearch(){
  return(
    <iframe id="prt_srch"
      title="Autoparts search engine"
      src="https://www.buscador.refaccionariaarboledas.com.mx">
    </iframe>
  )
}