import React, { useState, useEffect } from "react";

export default function CustomInput(props){
  const [value, changeValue] = useState(props.value ? props.value : '');
  const textarea = (<textarea id={props.id} pattern={props.pattern} onChange={inputChange} required={props.required} value={value}></textarea>); 
  const input = (<input id={props.id} pattern={props.pattern} type={props.type} onChange={inputChange} required={props.required} value={value}></input>); 

  function mouseEnter(event){
    event.target.parentNode.getElementsByTagName('p')[0].classList.add('csm_in_slct');
  }

  // TODO: solve problem with event.target for helper
  function mouseLeave(event){
    let target; 
    if(event.target.parentNode.getElementsByTagName('input').length === 1){
      target = event.target.parentNode.getElementsByTagName('input')[0]; 
    }else{
      target = event.target.parentNode.getElementsByTagName('textarea')[0]; 
    }

    try{
      if(!target.value){
        target.parentNode.getElementsByTagName('p')[0].classList.remove('csm_in_slct');
      }
    }catch(err){
      console.log(err);
    }
  }

  function inputChange(event){
    changeValue(event.target.value);
    if(event.target.value){
      event.target.parentNode.getElementsByTagName('p')[0].classList.add('csm_in_slct');
    }else{
      if(event.target !== document.activeElement){
        event.target.parentNode.getElementsByTagName('p')[0].classList.remove('csm_in_slct');
      }
    }
  }

  function showHelper(event){
    try{
      event.target.getElementsByTagName('p')[0].classList.add('csm_in_hpr_sh');
    }catch(err){
      console.log(err);
    }
  }

  function hideHelper(){
    document.getElementsByClassName('csm_in_hpr_sh')[0].classList.remove('csm_in_hpr_sh');
  }

  return(
    <div className="csm_in" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <p id="phdr" className={value ? "csm_in_slct" : null}>{props.placeholder}</p>
        {props.type === "textarea" ? textarea : input}
        {props.helper ? (<figure onMouseEnter={showHelper} onMouseLeave={hideHelper}>
        <img src="../helpButton.svg" alt="Helpbox"></img>
        <p id="csm_in_hpr">{props.helper}</p>
      </figure>): null}
    </div>
  ); 
}
