import React from "react";

export default function DisabledInput(props){
  return (
    <div id={props.containerId} className="dpd dpd_disabled">
      <input id={props.inputId} type="text" placeholder={props.placeholder} disabled></input>
      <figure>
        <img src={props.image} alt="Dropdown icon"></img>
      </figure>
    </div>
  );
}