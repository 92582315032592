import * as React from "react";
import { useDropzone } from "react-dropzone";
import "core-js/modules/es.array.from";

const DropZone = (props) => {
  const [myFiles, setMyFiles] = React.useState([]);

  const onDrop = React.useCallback(
    acceptedFiles => { 
      props.saveFiles({...props.data, files: [...acceptedFiles]});
      setMyFiles([...acceptedFiles]);
    },
    [props]
  );  

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    noKeyboard: true,
    maxSize: 20971520,
    multiple: false,
    maxFiles: 1,
    onDrop
  });

  const files = myFiles.map(file => (
    <li key={file.path} className="dpz_fls">
      <figure>
        <img src="../image.svg" alt="Dropzone"></img>
      </figure>
      <p>{file.path}</p>
      <figure className="dpz_cls" onClick={() => {
          const newFiles = [...myFiles];
          newFiles.splice(newFiles.indexOf(file), 1);
          props.saveFiles({...props.data, files: newFiles});
          setMyFiles(newFiles);
        }
      }> 
        <img src="../quit.svg" alt="Dropzone"></img>
      </figure>
    </li>
  ));

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone", style: baseStyle })}>
        <input {...getInputProps()} />
        <p id="dpz_txt">Arrastra la foto de perfil o cárgala desde tu ordenador</p>
      </div>
      {files.length > 0 ? (
        <div>
          <h4>Imágen seleccionada</h4>
          <div>{files}</div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default DropZone;
