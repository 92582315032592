import React, { useState } from "react";

export function Loading(){
  return(
    <div id="popup_bg">
      <figure id="ldng_cntr">
        <img src="/loading.gif"></img>
      </figure>
    </div>  
  );
}

export function Completed(props){
  let message; 
  if(props.message){
    message = props.message; 
  }else if(props.type === "SUCCESS"){
    message = "Completado con éxito"
  }else{
    message = "Lo sentimos, ha habido un error"
  }

  return(
    <div id="popup_bg" onClick={(event) => {
      if(event.target === document.getElementById('popup_bg')){
        props.close();
      }
    }}>
      <div id="popup_cntr">
        <figure>
          <img src={props.type === "SUCCESS" ? "/checked.svg": "/close.svg"} alt="Popup Type"></img>
        </figure>
        <p>{message}</p>
      </div>
    </div>  
  );
}