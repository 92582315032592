import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import CustomDropdown from '../CustomDropdown/CustomDropdown'; 
import axios from 'axios';

export default function Traffic(){
  const [selectedHours, setSelectedHoursState] = useState(1);
  const [lastHourValue, setLastHourValueState] = useState(null);
  const [chart, setChartState] = useState({
    options: {
      stroke: {
        show: true,
        width: 2, 
        dashArray: 2
      }, 
      markers: {
        size: 0
      }, 
      colors:['#EC0000', '#E91E63', '#9C27B0'],
      xaxis: {
        type: 'datetime', 
        labels: {
          format: 'dd/MM/yy - HH/mm/ss'
        }
      }
    },
    series: [
      {
        name: "Peticiones",
        data: []
      }
    ]
  });

  useEffect(() => {
    if(!lastHourValue || lastHourValue !== selectedHours){
      axios.get(`${process.env.REACT_APP_GET_REQUESTS_SETS}${selectedHours*60}`).then(response => {
        const requests = JSON.parse(response.data.body).sets; 

        const charData = requests.map(set => {
          return {
            x: new Date(set.timestamp).getTime(), 
            y: set.amount
          }
        });
        setChartState({
          options: chart.options, 
          series: [{
            name: chart.series[0].name, 
            data: charData
          }]
        });
      });
      setLastHourValueState(selectedHours); 
    }
  }, [chart.options, chart.series, lastHourValue, selectedHours])

  return (
    <div className="mn_ct">
      <div id="tfc_hdr">
        <CustomDropdown type="timelapse" valueSelected={null} disabled={false} dropdownValues={["1 hora", "12 horas","7 días","15 días","90 días"]} completed={(value) => {
        switch(value){
          case "1 hora": 
            setSelectedHoursState(1);
            break; 
          case "12 horas":
            setSelectedHoursState(12);
            break;  
          case "7 días":
            setSelectedHoursState(168);
            break; 
          case "15 días":
            setSelectedHoursState(360);
            break; 
          case "90 días":
            setSelectedHoursState(2160);
            break;
          default: 
            setSelectedHoursState(24);
            break; 
        }
      }}></CustomDropdown>
      </div>
      <div className="mixed-chart">
        <Chart
          options={chart.options}
          series={chart.series}
          type="area"
        />
      </div>
    </div>
  );
}