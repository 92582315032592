import React from "react";
import CustomInput from './CustomInput'; 
import { Auth } from 'aws-amplify';

export default function ChangePassword(props) {
  return (<div id="fspup" onClick={(event) => {
    if(event.target !== document.getElementById('fspup')){
      return; 
    }

    props.close(); 
  }}>
    <div id="cnpup">
      <p id="tle">{props.title}</p>
      <p id="cnpup_des">Por tu seguridad, ingresa tu contraseña actual para posteriormente registrar tu nueva contraseña.</p>
      <CustomInput id="ipt_old_pass" placeholder="Contraseña actual" type="password"></CustomInput>
      <CustomInput id="ipt_new_pass" placeholder="Nueva contraseña" type="password"></CustomInput>
      <div>

      </div>
      <button className="cnpup_btn" onClick={async() => {
          document.getElementById('fspup').style.display = 'none';
          props.setLoading(true);

          let user = await Auth.currentAuthenticatedUser();
          console.log(user);
          try{
            await Auth.changePassword(
              user,
              document.getElementById('ipt_old_pass').value,
              document.getElementById('ipt_new_pass').value 
            );
            props.setLoading(false);
            props.setProcessStatus(true);
          }catch(err){
            console.log(err);
            props.setLoading(false);
            props.setFailureStatus(true);
          }
        }
      }>Cambiar</button>
    </div>
  </div>);
}
