import React, { useState, useEffect} from "react";
import Menu from './Menu'; 
import ChangePassword from './ChangePassword'; 
import ChangeEmail from './ChangeEmail'; 
import { isElementClicked } from "./helper";
import EmployeeEdition from './EmployeeEdition'; 
import axios from 'axios';
import { Base64 } from 'js-base64';
import { Loading, Completed } from './GenericPopup';

export default function Profile() {
  const [menuActivated, setMenuState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [failed, setFailureStatus] = useState(false);
  const [editEmployeeActivated, setEmployeeState] = useState(false);
  const [changePasswordActivated, setPasswordState] = useState(false);
  const [changeEmailActivated, setEmailState] = useState(false);
  const [gotEmployees, setGotEmployeesState] = useState(false); 
  const [employeesData, setEmployeesState] = useState(sessionStorage.getItem('employees')); 
  const [profileData, setProfileState] = useState(null); 

  useEffect(() => {
    if(!gotEmployees || !employeesData){
      (async () => {
        try{
          let response = await axios({
            method: 'get',
            url: process.env.REACT_APP_GET_EMPLOYEES
          });
          
          setEmployeesState(JSON.parse(response.data.body).employees);
          sessionStorage.setItem('employees', JSON.stringify(JSON.parse(response.data.body).employees)); 
          setGotEmployeesState(true); 
        }catch(err){
          console.log(err);
        }
      })(); 
    }

    if(employeesData){
      const user = JSON.parse(sessionStorage.getItem('session')).attributes.email;
      const keys = Object.keys(employeesData); 
      let value; 
      for(let i = 0; i < keys.length ; i++){
        if(employeesData[i].email === user){
          value = employeesData[i]; 
          break; 
        }
      }

      setProfileState(value); 
    }
  }, [gotEmployees, employeesData, profileData]);

  const menu = [
    {
      "label": "Editar perfil", 
      "action": () => {
        setEmployeeState(!editEmployeeActivated); 
      }
    }, 
    {
      "label": "Cambiar contraseña", 
      "action": () => {
        setPasswordState(!changePasswordActivated); 
      }
    }, 
    // {
    //   "label": "Cambiar correo", 
    //   "action": () => {
    //     setEmailState(!changeEmailActivated); 
    //   }
    // }
  ]; 

  const clickOutsideToClose = evt => {
    if(!isElementClicked(evt, 'mnu')){
      document.removeEventListener("click", clickOutsideToClose);
      setMenuState(false); 
    }    
  }

  const image = 'https://d36f5t8nk78xg4.cloudfront.net/' + Base64.encode(JSON.stringify({
    "bucket": "arboledas-storage",
    "key": profileData ? profileData.image_location : '',
    "edits": {
      "resize": {
        "width": 150,
        "height": 150,
        "fit": "cover",
      },
    }})); 

  return (<div id="prf">
    {editEmployeeActivated && <EmployeeEdition title="Editar perfil" userData={profileData} close={() => {setEmployeeState(!editEmployeeActivated)}}></EmployeeEdition>}
    {changePasswordActivated && <ChangePassword setFailureStatus={setFailureStatus} setProcessStatus={setProcessStatus} setLoading={setLoading} title="Cambiar contraseña" close={() => {setPasswordState(!changePasswordActivated)}}></ChangePassword>}
    {changeEmailActivated && <ChangeEmail title="Cambiar correo" close={() => {setEmailState(!changeEmailActivated)}}></ChangeEmail>}
    {loading && <Loading/>}
    {completed && <Completed type="SUCCESS" message={'Contraseña cambiada con éxito'} close={() => setProcessStatus(false)}/>}
    {failed && <Completed type="ERROR" message={'Ha habido un error al cambiar la contraseña'} close={() => setFailureStatus(false)}/>}
    <div id="prf_hdr">
    <figure className="hdr_m" onClick={() => {
        if(!menuActivated){
          setMenuState(!menuActivated); 
          document.addEventListener("click", clickOutsideToClose)
        }
      }}>
        <img src="/menu.svg" alt="Menu"></img>
        {menuActivated && <Menu items={menu} close={() => setMenuState(!menuActivated)}></Menu>}
      </figure>
    </div>
    <div id="prf_cnt">
      <figure id="prf_pct">
        <object data={image}>
          <img src="/profile.svg" alt="No profile"></img> 
        </object>
      </figure>
      <div id="prf_tbl">
        <div className="prf_tbl_rw">
          <p className="tbl_des">Nombre</p>
          <p>{profileData ? profileData.fullname : '--'}</p>
        </div>
        <div className="prf_tbl_rw">
          <p className="tbl_des">Puesto</p>
          <p>{profileData ? profileData.job_title : '--'}</p>
        </div>
        <div className="prf_tbl_rw">
          <p className="tbl_des">Email</p>
          <p>{profileData ? profileData.email : '--'}</p>
        </div>
        <div className="prf_tbl_rw">
          <p className="tbl_des">Teléfono</p>
          <p>{profileData ? profileData.phone : '--'}</p>
        </div>
      </div>
    </div>
  </div>
  );
}
