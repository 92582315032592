import React, { useState, useEffect } from "react";
import ReactMapGL, {Marker} from 'react-map-gl';
import CustomDropdown from '../CustomDropdown/CustomDropdown'
import axios from 'axios';

export default function TrafficByLocation(){
  const [viewport, setViewport] = useState({
    latitude: 24.334584,
    longitude:  -99.1269,
    zoom: 4
  });
  const [selectedHours, setSelectedHoursState] = useState(1);
  const [lastHourValue, setLastHourValueState] = useState(null);
  const [markers, setMarkers] = useState(null);

  useEffect(() => {
    if(!lastHourValue || lastHourValue !== selectedHours){
      axios.get(`${process.env.REACT_APP_GET_SEARCH_METRICS}${selectedHours}`).then(response => {
        const requests = JSON.parse(response.data.body).request; 
        
        const markers = []; 
        requests.forEach(request => {
          markers.push(
            <Marker latitude={parseInt(request.latitude)} longitude={parseInt(request.longitude)} offsetLeft={-20} offsetTop={-10}>
              <div class="mkr"></div>
            </Marker>
          );
        });
        setMarkers(markers);
      });
      setLastHourValueState(selectedHours); 
    }
  }, [lastHourValue, selectedHours])

  return (
    <div id="tfc">
      <div id="tfc_hdr">
        <CustomDropdown type="timelapse" valueSelected={null} disabled={false} dropdownValues={["1 hora", "12 horas","7 días","15 días","90 días"]} completed={(value) => {
          switch(value){
            case "1 hora": 
              setSelectedHoursState(1);
              break; 
            case "12 horas":
              setSelectedHoursState(12);
              break;  
            case "7 días":
              setSelectedHoursState(168);
              break; 
            case "15 días":
              setSelectedHoursState(360);
              break; 
            case "90 días":
              setSelectedHoursState(2160);
              break;
            default: 
              setSelectedHoursState(24);
              break; 
          }
        }}></CustomDropdown>
      </div>
      <div id="map">
        <ReactMapGL
          {...viewport}
          onViewportChange={nextViewport => setViewport(nextViewport)}
          mapboxApiAccessToken="pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w"
        > 
          {markers}
        </ReactMapGL>
      </div>
    </div>
    );
}