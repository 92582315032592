import axios from 'axios';

export const perfectSpace = (navbarWidth, elementWidth, elementsClassName, parentContainer) => {
  const elements = Math.floor((window.innerWidth - navbarWidth) / elementWidth); 
  const parentPadding = 15; 
  const freeSpace = (window.innerWidth - navbarWidth) - (elementWidth * elements) - parentPadding * 2;
  const margin = (freeSpace / (elements * 2) - 1) + "px"; 
  document.getElementById(parentContainer).style.padding = parentPadding + 'px'; 
  const elementsPainted = document.getElementsByClassName(elementsClassName);
  for(let i = 0; i < elementsPainted.length; i++){
    elementsPainted[i].style.margin = margin; 
  }
}

export const isElementClicked = (evt, element) => {
  let flyoutElement; 
    try{
      flyoutElement = document.getElementById(element);
    }catch(err){}

    let targetElement = evt.target; 

    do {  
      if (targetElement === flyoutElement) {
        return true;
      }
      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement);

    return false;
}

export const getAccountData = async (session) => {
  sessionStorage.setItem('jwt', session.signInUserSession.idToken.jwtToken);

  try{
    let response = await axios({
      method: 'get',
      url: process.env.REACT_APP_GET_ACCOUNT,
      headers: {
        Authorization: session.signInUserSession.idToken.jwtToken 
      }
    });
  
    sessionStorage.setItem('company', JSON.stringify(response.data.company)); 
    sessionStorage.setItem('user', JSON.stringify(response.data.user)); 
  }catch(err){
    console.log(err);
  }
}