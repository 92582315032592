import { Auth } from 'aws-amplify';
import React, { useState } from "react";
import Dashboard from './Dashboard';
import { PRSendEmail, PRUpdate } from './PasswordRecovery';
// import { RegisterCompanyData, RegisterAdminData, RegisterValidateCode } from './RegisterAccount';
import { SignIn } from './SignIn';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  Redirect
} from "react-router-dom";
// import { getAccountData } from './helper';

export default function App(props) {
  const sessionStorageCompanyData = JSON.parse(sessionStorage.getItem('company')); 
  const sessionStorageUserData = JSON.parse(sessionStorage.getItem('user')); 

  const [accountData, setNewAccountState] = useState({
    authenticated: sessionStorage.getItem('auth') || false,
    userData: sessionStorageUserData, 
    companyData: sessionStorageCompanyData
  });
  const [passwordRecoveryEmail, setPasswordRecoveryEmailState] = useState(null);


  async function sessionStatus(){
    try{      
      if(!accountData.authenticated){
        const cognito = await Auth.currentAuthenticatedUser();
        // getAccountData(cognito);
      }
    }catch(err){
      console.log(err);
    }
  }

  sessionStatus();
  return (
    <Router>
        <Switch>
          <Route path="/login" render={(props) => <LogoutBasicSctructure {...props}><SignIn /></LogoutBasicSctructure>} />
          <Route path="/password-recovery/update" render={(props) => <LogoutBasicSctructure {...props}><PRUpdate email={passwordRecoveryEmail}/></LogoutBasicSctructure>} />
          <Route exact path="/password-recovery" render={(props) => <LogoutBasicSctructure {...props}><PRSendEmail saveEmail={setPasswordRecoveryEmailState}/></LogoutBasicSctructure>} />
          <PrivateRoute path="/" isAuthenticated={setNewAccountState}>
            <Dashboard />
          </PrivateRoute>
        </Switch>
    </Router>
  );
}

function PrivateRoute (props){
  const authenticated = sessionStorage.getItem('auth') === 'true' ? true : false; 

  return (
    <Route
      render={({ location }) =>
        authenticated ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export function LogoutBasicSctructure(props){
  return(
    <div id="lgn">
      <div id="lgn_hdr">
        <figure>
          <img src="/logo.svg" alt="Logo"></img>
        </figure>
      </div>
      <div id="lgn_main">   
        {props.children}     
      </div>
    </div>
  );
}